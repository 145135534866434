:root {
  --dark-bg: #101320;
  --light-bg: white;
  --grey-bg: #333643;
  --text-white: white;
  --text-dark: #0f111b;
  --text-secondary: #15c0f0;
}

.heading {
  font-size: 4.5rem;
  font-weight: 800;
} 

.text {
  font-size: 2.5rem;
  font-weight: 300;
}


@media only screen and (max-width: 500px) {
  .heading {
    font-size: 3.5rem;
  } 
  
  .text {
    font-size: 1.5rem;
  }
}